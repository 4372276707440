import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "layout"
import Container from "layout"

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    {
      spilledMeds: file(relativePath: { eq: "pages/404__spilledMeds.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const spilledMeds = data.spilledMeds.childImageSharp.fluid

  return (
    <Layout 
      display={{ helpCenterBanner: true, footer: true }} 
      seoTitle="404: Not found"
    >
      <center className="my-2">
        <div className={`column is-10-mobile is-8-tablet is-6-desktop is-6-fullhd`}>
          <Img fluid={spilledMeds} />
        </div>
        <h2>Something’s wrong here...</h2>
        <p>
          We can’t find the page you entered. You may visit our{" "}
          <Link to="/help-center" className="has-text-weight-bold">
            Help Center
          </Link>
          , <br className="is-hidden-mobile" /> or{" "}
          <Link to="/help-center#contact-us" className="has-text-weight-bold">
            contact us
          </Link>{" "}
          for further assistance.
        </p>
      </center>
    </Layout>
  )
}

export default NotFoundPage
